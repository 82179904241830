/*


*/
export const options = [
  {
    id: 8,
    alias: "Ballys",
    url: "https://www.ballys.co.uk",
    role: "Principal Developer",
    responsibilities: "**Ballys design system, component library, theme package, ceremonies, theme releases, integration support, mentoring**",
    from: "Feb 2022",
    to: "Present",
    location: "Piccadilly Circus",
    description: [
      "Playing a pivotal role within our internal design system team in delivering a cutting-edge, bespoke design system that is focused on scalability and rapid adoption. My responsibilities encompass a wide array of technical and collaborative aspects, including:",
      "**Technology Stack:** & Team I lead the proof of concepts, demos, training, presentations, team ceremonies and decisions. I advised using modern front-end technologies and tools like React, Material UI, Zeroheight, Storybook, and Figma to craft a versatile and user-friendly design system that can seamlessly integrate across our entire company.",
      "**Scalability and Brand Adaptation:** One of my key achievements has been successfully scaling the design system to cater to multiple brands (virgingames, jackpotjoy, monopoly, heart). This involves creating a robust theming system, allowing for quick and easy theme updates, thereby ensuring that our applications maintain a cohesive and on-brand look and feel.",
      "**Developer Support & Collaboration:** In a collaborative environment, I actively support other teams and developers in integrating our design system into their applications. This includes providing comprehensive documentation, conducting training sessions, and offering hands-on assistance to ensure a smooth and efficient integration process.",
      "**Tooling and Ecosystem Enhancement:** I took ownership of creating and managing Storybook addons, Figma plugins, and bespoke tooling that streamline the development process. This empowers our team to work more efficiently and ensures that our design system is readily accessible and adaptable.",
      "**Quality Assurance & Testing:** To maintain the high-quality standards of our design system, I've implemented a robust visual regression testing suite. This includes setting up quality gates for production releases, ensuring that any design changes meet stringent quality criteria before deployment.",
      "**Design Token Generation:** I've devised a solution that reads Figma API responses and dynamically converts them into design tokens on the fly. This innovation has significantly expedited the design-to-development process, reducing manual work and the likelihood of inconsistencies.",
      "**Micro-Frontend Updates:** Our design system allows for micro-frontend design updates delivered from a core client wrapper, providing designers with more autonomy and control over the user interface. This approach enhances collaboration, as it ensures that design changes can be quickly and accurately implemented without extensive developer involvement."
    ]
  },
  {
    "id": 7,
    "alias": "GameSys",
    "url": "https://www.gamesyscorporate.com/",
    "role": "Senior Front-end Developer",
    "responsibilities": "**Bespoke whitelabel website, feature building, TDD, sprint delivery**",
    "from": "January 2016",
    "to": "Feb 2022",
    "location": "Piccadilly Circus",
    "description": [
      "Supporting the launch of [virgingames.com](https://www.virgingames.com), [jackpotjoy.com](https://www.jackpotjoy.com) and [Gamesys Spanish ventures](https://botemania.es)",
      "Daily pull request reviews, weekly tech considerations, tutoring junior developers, technically advising product owners",
      "Working on a team of senior developers to prototype future core feature",
      "Developing on, and enhancing a SSR React codebase and advocating its growth within the business"
    ]
  },
  {
    "id": 6,
    "alias": "BT",
    "url": "https://www.bt.com/mobile/",
    "role": "Front-end Development Lead",
    "responsibilities": "**Scrum Master, Recruitment Manager, Code Quality Manager, Project Manager**",
    "from": "July 2014",
    "to": "December 2016",
    "location": "St Pauls",
    "description": [
      "Scrum Master, Recruitment Manager, Standards Manager & JavaScript Coder", "Extended AirBnB code standards and enforced lint rules into day to day development",
      "Managed the hiring process to help hire developers, project managers and content editors (12+ people)", "Technical consultation for internal, offshore and agency stake holders",
      "Implemented Scrum into BT.com development processes", "Created BT’s job standards for Front-end Development",
      "Responsible for delivering BT Mobile - a 100% online mobile store managed via internal and external APIs using React JS. Built an in-house team to experiment and deliver a solid solution for BT.com future.",
      "Delivered many projects (as technical and development lead) to retain and entice customers; Bespoke offers, Intelligent sales system, TV redesign, Broadband redesign, BT Smart HUB 6, EE conversion"
    ]
  },
  {
    "id": 5,
    "alias": "theAudience",
    "url": "https://www.theAudience.com",
    "role": "Director of Technology",
    "responsibilities": "**IT Support, Development & Project Advice**",
    "from": "August 2013",
    "to": "May 2014",
    "location": "Soho / Shoreditch / Hollywood",
    "description": [
      "Ensured that all users had a seamless experience during a transition between IT support companies.",
      "Daily responsibilities included engaging with other tech companies, overseeing internal projects and providing top level feedback and guidance while developing internal apps.",
      "Built an in house systems that could streamline HR management internally."
    ]
  },
  {
    "id": 4,
    "alias": "theAudience",
    "url": "https://www.theAudience.com",
    "role": "Technical Product Owner",
    "responsibilities": "**Team Management, PHP & JS Code Sign Off, Project Specifications & Budget**",
    "from": "March 2013",
    "to": "August 2013",
    "location": "Shoreditch / Hollywood",
    "description": [
      "Following the successful launch and support of an internal CMS, I was tasked with determining end user needs to create a feedback loop for writing solid project specifications for the development team.",
      "Generated detailed wireframes and specification documents based on user and stakeholder requirements. Also led high level pointing sessions and large scale planning to visually sell to stakeholders the expense of their decisions.",
      "Continued to guide the new web development manager, coding daily with the team and reviewing code for project releases."
    ]
  },
  {
    "id": 3,
    "alias": "theAudience",
    "url": "https://www.theAudience.com",
    "role": "Web Development Manager",
    "responsibilities": "**PHP Coder & Engineer, Team Manager, Test Manager & Scrum Master**",
    "from": "March 2011",
    "to": "March 2013",
    "location": "Soho / Shoreditch / Hollywood",
    "description": [
      "Tasked with creating a custom CMS that would enable a growing team to creating curated content for clients.",
      "Given free reign to build a team housed in the WME offices, a id investor. Built a team of freelancers & worked tirelessly to develop a system with shifting priorities. Successfully delivered in 9 months, it became a vital part of growing the business, leading to a massive expansion for the UK branch of theAudience.",
      "Engineered a custom PHP API to create rapid prototypes and teamed it with Zend Framework codebase for the front-end initially.",
      "Switched the front-end to use cutting edge technology such as alpha BackboneJS, RequireJS, UnderscoreJS and mongoDB. Expanded the system into a client facing CMS as a id stake holder and develop",
      "Daily tasks included managing the team development, developing both JS and PHP code, running daily stand ups & planning sessions and reporting back to the project manager."
    ]
  },
  {
    "id": 2,
    "alias": "LF",
    "role": "Project Lead",
    "responsibilities": "Zend Framework, Testing, eCommerce & High Level Project Specifications",
    "url": "",
    "from": "September 2009",
    "to": "April 2011",
    "location": "Soho",
    "description": [
      "Created a video on demand system using the Zend Framework and Wowza Media Service that allowed users to buy monthly memberships or per minute credits across multiple currencies. Achieved this in 5 months after given an 8 month timeline.",
      "Created an affiliate management system that would give clients real time information on streaming information, achieved in 2 months. Was then tasked with creating a white label system for these clients, the result which was eventually bought by a large retail chain.",
      "Daily tasks included installing Interspire Email Marketer and migrating Interspire Shopping Cart to Magento, a difficult 3 month project that taught me extensively on database engineering and table conversion."
    ]
  },
  {
    "id": 1,
    "alias": "Marko Media",
    "url": "https://www.linkedin.com/company/markco-media/about/",
    "role": "Web Development Manager",
    "responsibilities": "Hiring, Team Management, PHP Code Base Engineering & Maintenance",
    "from": "January 2007",
    "to": "September 2009",
    "location": "Croydon",
    "description": [
      "As a PHP developer, excelled at delivering new features more rapidly than expected; was recognized as the stronger developer on a distributed team.",
      "Was granted request to rewrite their entire framework using my own methodologies, as I expected their system would be unable to cope with the large amount of traffic the site was beginning to generate. Managed this remotely with a team in Rotherham while opening up an office in London. Was then tasked with building a team of 10 web developers, achieved in two months.",
      "Having built the team, was then given control of managing the entire framework and its future development to create exciting new features."
    ]
  }]

export default options;
export const options = [
	"**15 years of commercial web development** experience",
	"Delivered [BT Mobile](https://www.bt.com/mobile/) using **React JS**",
	"*ES2016*, ES6 and beyond",
	"Creating a runtime multi-brand design system with **Storybook**, **React**, MUI with Figma & APIs",
	"Component testing; visual, unit and interaction",
	"5 years experience managing web development teams (**PDP, 1:1, L&D, HR**)",
	"Module bundlers like **Webpack**, TSDX, SWC",
	"**SASS** & LESS compiling, JSS experience",
	"Qualified **Agile and Scrum Certification** and Training completed with Mike Cohn",
	"Delivered an **internal CMS & SPA experiences** (Backstage, Stage) to empower theAudience team and its clients",
	"Advanced understanding of SEO and techniques to improve search ranking",
	"Advising and implementing process to **improve website speed** (minification, bundling, image optimisations)",
	"Excellent mobile experience; ensuring sites such as BT.com, myvouchercodes.co.uk, jackpotjoy.com, virgingames.com are **responsive** across mobile, tablet and desktop",
	"Running a democratic **design council** with senior front-end developers to vote on code rules & processes, discuss tools & libraries and agree on frameworks to use",
	"DevOps experience advising on deployment processes, server set up & requirements",
	"Created and enforced code standards by putting **pull request & reviews** at the core of code delivery. Seconded only by enforcing code lint standards pass 100% before being able to commit",
	"Technically advised on Backbone, Angular and **React** project deliveries",
	"Create an internal task force to help BT host JavaScript meet ups inside BT (JS Meet up currently hosted)",
	"Providing high level estimates for business critical projects",
	"Running ceremonies such as **stand-up, retrospectives, sizing**",
	"Many years of experience in developing and working with APIs",
]

export default options;
